import React,{useState} from 'react';
import logo from '../../src/assets/HSKN-Updated Sunglasses.png';
import twitter from '../../src/assets/twitter.png';
import tik from '../../src/assets/tik.png';
import dis from '../../src/assets/Discord.png';
import you from '../../src/assets/you.png';
import med from '../../src/assets/med.png';
import './Main.css'; 

function Main() {
    const [tooltipVisible, setTooltipVisible] = useState(false);
   


    const handleButtonClick = () => {
      const textToCopy = "8344f34d8caa329098ebe1c62c4736bddfdd407ae528afd936f34493";
      navigator.clipboard.writeText(textToCopy).then(() => {
        setTooltipVisible(true);
        setTimeout(() => {
          setTooltipVisible(false);
        }, 2000); // Hide tooltip after 2 seconds
      });
    };
  return (
    <div className="App">
      <div className="blackboard-container">
        <h1 className="heading">CHARL HUSKEN</h1>
        <p className="text2">Force behind $HSKN,</p>
        <p className="text2">The last unreviewed memecoin on the Cardano blockchain.</p>
        <div className="links">

          <a href=" https://x.com/CharlHusken " target="_blank">
            <img src={twitter} alt="Twitter" />
          </a>

          <a href="https://discord.gg/ycghHwfHzR " target="_blank">
            <img src={dis} alt="Discord" />
          </a>
          
          <a href="https://www.tiktok.com/@charlhusken?is_from_webapp=1&sender_device=pc " target="_blank">
            <img src={tik} alt="TikTok" />
          </a>
          <a href=" https://www.youtube.com/channel/UC6ixSskGyYZ_dQcWCwff2fQ " target="_blank">
            <img src={you} alt="YouTube" />
          </a>
          <a href="https://medium.com/@charl_husken " target="_blank">
            <img src={med} alt="Medium" />
          </a>
        </div>
        <div className="button-text-container">
          
          <p className="text3">A coin so hip, it memes business!</p>
          <div className="thebutton-container">
            <button className="thebutton" onClick={handleButtonClick}>
            Policy ID
            </button>
            {tooltipVisible && (
              <div className="tooltip">Link "8344f34d8caa329098ebe1c62c4736bddfdd407ae528afd936f34493" copied</div>
            )}
          </div>
        </div>
        <img src={logo} alt="Logo" className="logo" />
      </div>
    </div>
  );
}

export default Main;
