import logo from './logo.svg';
import './App.css';

import Header from './components/header/Header';
import Main from './main/Main'

function App() {
  return (
    // <div className="App">
   <Main/>
    // </div>
  );
}

export default App;
